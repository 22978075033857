"use client";
import { Box, Typography, Grid, TextField, Button } from "@mui/material";

import React, { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
// import { Login } from "@/app/api/auth/auth";

import { signIn } from "next-auth/react";
import { toast } from "react-toastify";
export default function LoginPage() {
  const [mounted, setMounted] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);
  const [loginForm, setLoginForm] = useState({
    username: "",
    password: "",
  });
  const router = useRouter();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLoginForm((prev) => ({ ...prev, [name]: value }));
  };

  if (!mounted) return null;

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setIsLogin(true);
    // Use signIn from NextAuth with credentials provider
    const result = await signIn("credentials", {
      redirect: false,
      username: loginForm.username,
      password: loginForm.password,
    });
    setIsLogin(false);
    if (result?.error) {
      toast.error("Incorrect username or password");
    }
    if (result?.error) {
      router.push("/");
    } else {
      router.replace("/dashboard");
      return;
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            justifyContent: "center",
            height: "100vh",
            padding: { xs: 2, sm: 5, md: 20 },
            background: "#4e9deb",
            display: "flex",
          }}
        >
          <Box
            sx={{
              width: { xs: "90%", sm: "90%", md: "80%", lg: "40%" },
              margin: "auto",
              padding: "50px",
              boxShadow:
                "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);",
              borderRadius: "10px",
              bgcolor: "background.paper",
            }}
          >
            <Box
              sx={{
                width: "90%",
                margin: "auto",
              }}
            >
              <Grid container direction="column" spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    textAlign="center"
                    sx={{ color: "#1876d2" }}
                  >
                    Login
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Username"
                    name="username"
                    type="text"
                    fullWidth
                    value={loginForm.username}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Password"
                    name="password"
                    type="password"
                    fullWidth
                    value={loginForm.password}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Box sx={{ marginTop: 2 }}></Box>

              <Button
                variant="contained"
                fullWidth
                disabled={isLogin}
                sx={{ bgcolor: "#1876d2" }}
                type="submit"
              >
                {/* Login */}
                {isLogin ? "Logging in..." : "Login"}
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
}
